import type { GetArticleQuery, GetPageQuery } from "~/generated/schema.graphcms";

export type NewsArticle = NonNullable<GetArticleQuery["article"]>;

export const isValidArticle = (article: GetArticleQuery["article"]): article is NewsArticle => !!article?.content;

export type NewsArticleContent = NonNullable<NewsArticle["content"]>;

export type NewsArticleContentReference = NewsArticleContent["references"][number];

export const isAssetReferences = (
	references: Array<NewsArticleContentReference>,
): references is Array<Extract<NewsArticleContentReference, { __typename?: "Asset" }>> => "id" in references[0];

export type Page = NonNullable<GetPageQuery["page"]>;
export type PageSections = Page["sections"];
export type PageSection = PageSections[number];

export type DownloadSection = Extract<PageSection, { __typename?: "Download" }>;
export type SimpleSection = Extract<PageSection, { __typename?: "SimpleSection" }>;
export type RichTextSection = Extract<PageSection, { __typename?: "RichTextSection" }>;

export const isDownloadSection = (section: PageSection): section is DownloadSection => {
	return "downloadUrlNonAndroid" in section && "moreWaysToPlay" in section;
};

export const isSimpleSection = (section: PageSection): section is SimpleSection => {
	return "title" in section && "content" in section;
};

export const isRichTextSection = (section: PageSection): section is RichTextSection => {
	return "richText" in section;
};
